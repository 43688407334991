import {
    SHOW_LOADING,
    HIDE_LOADING
} from "../../utils/constants/settings";

export const onShowLoading = () => {
    return dispatch => {
        dispatch({ type: SHOW_LOADING });
    }
};

export const onHideLoading = (force) => {
    return dispatch => {
        dispatch({ type: HIDE_LOADING, force: force });
    }
};