import React, { Component } from 'react';

import TagLine from "../../img/Tagline_20210504.png";
import Icon01 from "../../img/icon-01.png";
import Icon02 from "../../img/icon-02.png";
import Icon03 from "../../img/icon-03.png";
import Icon04 from "../../img/icon-04.png";
import Icon05 from "../../img/icon-05.png";
import FooterFacebookIcon from "../../img/Homepage-06-footer-fb.png";

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            message: "",
            mailStatus: ""
        };

        this._onClear = this._onClear.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
    }

    _onClear() {

        this.setState({
            name: "",
            email: "",
            message: ""
        })
    }

    _onSubmit() {

        const { name, email, message } = this.state;
        const me = this;
        const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

        if (name === "" ||
            email === "" ||
            message === "" || !emailValid) {
            this.setState({
                mailStatus: "invalid"
            })
            return;
        }

        var data = new FormData();
        data.append("name", name);
        data.append("email", email);
        data.append("message", message);

        fetch('./mail/sendmail.php', {
            method: 'POST',
            body: data
        })
            .then(function (response) {
                response.text().then(function (s_response) {
                    me.setState({
                        mailStatus: s_response
                    });
                });
            })
    }

    render() {
        const { locale } = this.props;
        //const { mailStatus } = this.state;

        return (
            <div className="col-12 px-0">
                <div id="Home" className={`d-flex banner ${locale}`}>
                    <div className="container">
                        <div className="col-12">
                            <div className="col-12 px-0">
                                <div className="col-12 px-0 content">
                                    <div className="tagline">
                                        <img alt="TagLine" src={TagLine} />
                                    </div>
                                    <div className="google-play">
                                        <a href='https://play.google.com/store/apps/details?id=com.ai8race&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                            <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="section-b" className="col-12">
                    <div className="container">
                        <div className="col-12 px-0">
                            <div className="title">
                                <span>全人工智能</span>
                                <span>‧</span>
                                <span>大數據預測賽馬結果</span>
                            </div>
                            <div className="content">將過往20年的賽馬資料，放進自家研發的人工智能模型，訓練多時。<br />絕無人為因素及偏好影響賽果預測</div>
                        </div>
                    </div>
                </div>
                <div id="section-c" className="col-12">
                    <div className="container">
                        <div className="col-12 px-0">
                            <div className="content" style={{ lineHeight: 1.2 /* For bigger text */ }}>
                                <span>今季2020/2021（至5月份），”高準確”人工智能模型成功預測獎金達</span>
                                <span className="highlight">240萬</span>
                                <span>包括3T獎金達</span>
                                <span className="highlight">140萬</span>
                                <span>。上季”高回報”模型獎金高達</span>
                                <span className="highlight">400萬</span>
                                <span>。</span>
                            </div>
                            <div className="content pt-4">
                                <span>下載手機APP，非會員可於每場截止投注後觀看貼士，已購買計劃之會員可於任何時間觀看貼士，絕非係馬後炮</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="section-d" className="col-12">
                    <div className="container">
                        <div className="col-12 px-0">
                            <div className="title">
                                <p className="text-center">早試早享受</p>
                                <p className="text-center">遲啲貴幾舊</p>
                                <a target="_blank" rel="noreferrer" href='https://play.google.com/store/apps/details?id=com.ai8race&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                    <div className="button">
                                        立即下載
                                    </div>
                                </a>
                            </div>
                            <div className="content">
                                <span>非會員可於截止投注後，開跑前看見賽馬預測結果，免費下戴，歡迎測試比較</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="section-e" className="col-12">
                    <div className="container">
                        <div className="col-12 px-0 text-center title">
                            <p>其他功能:</p>
                        </div>
                        <div className="col-12 px-0 text-center content">
                            <div className="d-md-flex col-xs-12 px-0">
                                <div className="col-xs-12 flex-fill">
                                    <img alt="icon-01" src={Icon01} />
                                    <p>即時賽馬賠率及結果</p>
                                </div>
                                <div className="col-xs-12 flex-fill">
                                    <img alt="icon-02" src={Icon02} />
                                    <p>賠率</p>
                                </div>
                                <div className="col-xs-12 flex-fill">
                                    <img alt="icon-03" src={Icon03} />
                                    <p>負磅</p>
                                </div>
                                <div className="col-xs-12 flex-fill">
                                    <img alt="icon-04" src={Icon04} />
                                    <p>評分</p>
                                </div>
                                <div className="col-xs-12 flex-fill">
                                    <img alt="icon-05" src={Icon05} />
                                    <p>檔位排序</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 px-0 text-center bottom">
                            <p>簡潔頁面，資料一目了然。</p>
                            <p>*資訊只供參考，一切以香港賽馬會資料為準。</p>
                        </div>
                    </div>
                </div>
                <div className="footer col-12 px-0">
                    <div className="social-media col-12 px-0">
                        <div className="google-play">
                            <a target="_blank" rel="noreferrer" href='https://play.google.com/store/apps/details?id=com.ai8race&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
                            </a>
                        </div>
                        <a target="_blank" rel="noreferrer" href='https://www.facebook.com/AI8Race'>
                            <div className="circle">
                                <img alt="facebook" src={FooterFacebookIcon} />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="tnc col-12 text-center">
                    <div className="container">
                        <div className="col-xs-12 col-md-7">
                            <a>Terms of use</a>
                            <a href="/privacy_policy.html">Privacy policy</a>
                            <a>Cookie policy</a>
                            <a>Disclaimer</a>
                        </div>
                        <div className="col-xs-12 col-md-5">
                            <span>Copyright © 2021 www.ai8race.com</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Home;