import React from 'react';

import { Box, Grid, CircularProgress } from "@mui/material";

export const Loading = ({ isLoading }) => {

    return (
        <React.Fragment>
            <Box sx={{ display: `${isLoading ? "block" : "none"}`, zIndex: 9999, position: "fixed", top: 0, left: 0, backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
                <Grid container sx={{ justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>
                    <CircularProgress size={50}/>
                </Grid>
            </Box>
        </React.Fragment>
    )
}
