import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ReactGA from 'react-ga';

import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";

import { Loading, RaceInfoModal } from "../../components"

import HTTPRequest from '../../utils/HTTPRequest';
import { convertNumberKnM } from '../../utils';

import WinIcon from '../../img/race/circleicon-W.png';
import QinIcon from '../../img/race/circleicon-Q.png';
import TceIcon from '../../img/race/circleicon-T.png';
import QttIcon from '../../img/race/circleicon-4circles-Or.png';

class Race extends Component {

    constructor(props) {
        super(props);

        this.state = {
            refreshing: false,
            open: false,
            o_race_info: {},
            a_seasons: [],
            data: [],
            s_season: ""
        };

        this._onHandleClose = this._onHandleClose.bind(this);
    }

    componentDidMount() {

        this._doGetHistorySeason();
    }

    _doGetHistorySeason() {

        const convertData = (data) => {
            const a_data = [];
            let s_season = "";
            data.forEach((item, i) => {
                a_data.push({
                    s_year: item.r_year,
                    s_text: item.r_text
                })

                if (i === 0) {
                    s_season = item.r_year;
                }
            })

            this.setState({
                a_seasons: a_data,
                s_season: s_season
            }, function () {
                this._doGetHistoryDate();
            })
        }

        this.setState({ refreshing: true });

        HTTPRequest.HTTPGetRaceHistorySeason(function (result, data) {

            if (result === "success") {
                convertData(data);
            } else {
                this.setState({ refreshing: false });
                if (data.r_stage === "0") {
                    return;
                }
            }
        }.bind(this));
    }

    _doGetHistoryDate() {

        const me = this;
        const { s_season } = this.state;
        this.setState({ refreshing: true });

        HTTPRequest.HTTPGetRaceHistoryDate(s_season, function (result, data) {

            if (result === "success") {
                me._convertData(data);
            } else {
                if (data.r_stage === "0") {
                    return;
                }
            }

            me.setState({ refreshing: false });
        });
    }

    _convertData(data) {

        const a_data = [];
        for (let i = 0; i < data.length; i++) {
            a_data.push({
                s_id: data[i].r_meeting_id,
                s_date: data[i].r_date,
                s_venue: data[i].r_venue,
                a_profit: data[i].r_profit
            });
        }

        this.setState({
            data: a_data
        });
    }

    _renderHeader() {

        const { data } = this.state;
        let a_data = [];
        if (typeof data[0] === 'object') {
            if (data[0].hasOwnProperty('a_profit')) {
                a_data = data[0].a_profit;
            }
        }

        const calTotalProfit = (s_model_id) => {
            let i_total_profit = 0;
            data.forEach((o_item) => {
                o_item.a_profit.forEach((o_profit) => {
                    // 14 = 16, 15 = 17
                    if (o_profit.r_model_id === s_model_id || o_profit.r_model_id === parseInt(s_model_id) + 2) {
                        i_total_profit = i_total_profit + o_profit.r_total_profit;
                    }
                });
            })

            return i_total_profit;
        }

        return (
            <div className="col-12 px-0">
                <div className="col-4 col-md-5">
                </div>
                <div className="col-8 col-md-7">
                    <div className="col-12 px-0">
                        <div className="col-3 px-0"></div>
                        <div className="d-flex col-9 px-0" style={{ backgroundColor: "white" }}>
                            {a_data.map((item, i) => {
                                return (
                                    <div key={`model-name-${i}`} className="col-6" style={{ minWidth: 60, paddingLeft: 10, paddingRight: 0 }}>
                                        <div style={{
                                            ...styles.modelText,
                                            textAlign: "center",
                                            borderColor: item.r_model_id === 14 || item.r_model_id === 16 ? "#ED1C24" : "green"
                                        }}>
                                            <div style={{ fontSize: 16 }}>{item.r_model_name}</div>
                                            <div style={{ paddingLeft: 2 }}>{`$${convertNumberKnM(calTotalProfit(item.r_model_id))}`}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _onHandleClose() {

        this.setState({
            open: false,
            s_meeting_id: "",
            s_model_id: ""
        })
    }

    _renderItem(data) {

        const getIconSource = (pool) => {
            if (pool === "WIN") {
                return WinIcon;
            } else if (pool === "QIN") {
                return QinIcon;
            } else if (pool === "TCE") {
                return TceIcon;
            } else if (pool === "QTT") {
                return QttIcon;
            } else {
                return null;
            }
        }

        const render3tProfit = (data) => {
            const a_render = [];
            if (typeof data.a_profit !== "undefined") {
                data.a_profit.forEach((profit) => {
                    const s_model_id = profit.r_model_id;
                    //const s_model_name = profit.r_model_name;

                    if (profit.hasOwnProperty("r_3t_data")) {
                        profit.r_3t_data.forEach((item, i) => {
                            a_render.push(
                                <div key={`3t-${data.s_id}-${i}`} style={{
                                    ...styles.modelText,
                                    marginBottom: 5,
                                    paddingHorizontal: 5,
                                    paddingVertical: 3,
                                    borderColor: s_model_id === 14 || s_model_id === 16 ? "#ED1C24" : "green",
                                    textAlign: "center"
                                }}>
                                    <div>{`3T`}</div>
                                    <div>{convertNumberKnM(item.r_profit)}</div>
                                </div>
                            )
                        })
                    }
                })
            }

            return a_render;
        }

        const onClick = (data, model) => {

            const o_race_info = {
                s_meeting_id: data.s_id,
                s_model_id: model.r_model_id,
                s_model_name: model.r_model_name,
                s_model_color: model.r_model_id === 14 || model.r_model_id === 16 ? "#ED1C24" : "green",
                s_date: data.s_date,
                s_venue: data.s_venue,
                s_venue_color: data.s_venue === "跑馬地" ? "#F00" : "#00F"
            }

            ReactGA.event({
                category: 'Get Race Prediction',
                action: data.s_date + ' - ' + model.r_model_name,
            });

            this.setState({
                open: true,
                o_race_info: o_race_info
            })
        }

        return (
            <div key={`history-${data.s_id}`} className="col-12 py-1" style={{ borderBottom: "1px solid gray" }}>
                <div className="d-flex col-12 px-0">
                    <div className="col-4 col-md-5">
                        <div>{data.s_date}</div>
                        <div style={{ color: data.s_venue === "跑馬地" ? "#F00" : "#00F" }}>{data.s_venue}</div>
                    </div>
                    <div className="col-8 col-md-7">
                        {typeof data.a_profit !== "undefined" ?
                            <div className="col-12 px-0">
                                <div className="col-3 px-0">
                                    {render3tProfit(data)}
                                </div>
                                <div className="d-flex col-9 px-0">
                                    {data.a_profit.map((item, i) => {
                                        return (
                                            <div key={i} className="col-6" style={{ minWidth: 60, textAlign: "center", paddingLeft: 10, paddingRight: 0 }}>
                                                <div style={{
                                                    ...styles.modelText,
                                                    flex: 1,
                                                    borderColor: item.r_model_id === 14 || item.r_model_id === 16 ? "#ED1C24" : "green"
                                                }}>
                                                    <div>
                                                        <div style={{ flexDirection: "row", flexWrap: 'wrap' }}>
                                                            {item.r_data.map((pool) => {
                                                                return (
                                                                    <div key={`${item.r_model_id}-${pool.r_pool_code}`} style={{ cursor: "pointer" }} onClick={() => onClick(data, item)}>
                                                                        <div className="d-flex px-1 py-1">
                                                                            <img
                                                                                style={{ width: 20, height: 20 }}
                                                                                src={getIconSource(pool.r_pool_code)}
                                                                            />
                                                                            <div style={{ paddingLeft: 5, opacity: pool.r_count > 0 ? 1 : 0 }}>{`${pool.r_count}`}</div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        <div style={{ flex: 1, paddingVertical: 2 }}>
                                                            <div style={{ textAlign: "center" }}>{`$${convertNumberKnM(item.r_total_profit)}`}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        )
    }


    render() {
        const { refreshing, a_seasons, data, s_season } = this.state;
        const { open, o_race_info } = this.state;

        return (
            <div className="col-12 px-0">
                <Loading
                    isLoading={refreshing}
                />
                <RaceInfoModal
                    open={open}
                    raceInfo={o_race_info}
                    onHandleClose={this._onHandleClose}
                />
                <div style={{ width: "100%", maxWidth: "500px", margin: "0 auto" }}>
                    <div className="col-12 px-0 pt-2 position-fixed" style={{ maxWidth: "500px", backgroundColor: "white", zIndex: 99 }}>
                        <div className="col-12" style={{ paddingTop: "10px" }}>
                            <FormControl>
                                <InputLabel>賽季</InputLabel>
                                <Select
                                    value={s_season}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        this.setState({ s_season: value }, function () {
                                            this._doGetHistoryDate();
                                        })
                                    }}>
                                    {a_seasons.map((item, i) => {
                                        return (
                                            <MenuItem key={`season-${i}`} value={item.s_year}>{item.s_text}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-12">
                            {this._renderHeader()}
                        </div>
                    </div>
                    <div className="col-12 px-0" style={{ marginTop: 130 }}>
                        {data.map((item, i) => {
                            return (
                                this._renderItem(item)
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    container: {
        flex: 1,
        backgroundColor: '#F5FCFF',
    },
    content: {
        flex: 1
    },
    button: {
        width: 80,
        height: 80,
        margin: 5,
        padding: 5,
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#FFF"
    },
    buttonText: {
        fontSize: 16,
        textAlign: "center"
    },
    modelText: {
        padding: 2,
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: "solid"

    }
};

export default Race;
