import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation, matchPath, NavLink } from 'react-router-dom'

import { Drawer, Box, AppBar, Toolbar, IconButton, Typography, Grid } from "@mui/material";

import axios from 'axios';

import logo from './img/logo.png';
import FacebookIcon from './img/Homepage-01-header-fb.png';

const AppLayout = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {

    }, []);

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex' }}>
                <AppBar className="app-main-header" component="nav" sx={{ zIndex: 999 }}>
                    <Toolbar className="app-toolbar" disableGutters={false}>
                        <NavLink className="app-logo mr-2 d-block" to="/">
                            <div className="d-none d-lg-block">
                                <img src={logo} alt="AI8Race" title="AI8Race" />
                            </div>
                            <div className="d-md-block d-lg-none">
                                <img src={logo} alt="AI8Race" title="AI8Race" />
                            </div>
                        </NavLink>
                        <div className="ml-auto top-nav">
                            <ul className="d-flex align-items-center list-inline">
                                <NavLink to="/race">
                                    <li className="d-inline-block list-inline-item text-nav px-3">
                                        <span>過往預測</span>
                                    </li>
                                </NavLink>
                                <a href="https://www.facebook.com/AI8Race" target="_blank" rel="noopener noreferrer">
                                    <li className="d-inline-block list-inline-item img-nav">
                                        <img alt="facebook" src={FacebookIcon} />
                                    </li>
                                </a>
                            </ul>
                        </div>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box sx={{ marginTop: "64px" }}>
                {props.children}
            </Box>
        </React.Fragment >
    );
};
export default AppLayout;
