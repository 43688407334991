import React, { useEffect, useState } from 'react';

import { Dialog, DialogTitle, DialogContent, Grid, CircularProgress } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

import { NumericFormat } from 'react-number-format';

import HTTPRequest from '../utils/HTTPRequest';
import { isEmpty } from '../utils/';

import WinIcon from '../img/race/circleicon-W.png';
import QinIcon from '../img/race/circleicon-Q.png';
import TceIcon from '../img/race/circleicon-T.png';
import QttIcon from '../img/race/circleicon-4circles-Or.png';

const HEADER_ODD_COLOR = "#EBEFF2"
const HEADER_EVEN_COLOR = "#D8DFE6"

const RACE_DEFAULT_COLOR = "#35AEE2";
const RACE_DEFAULT_COLOR_LIGHT = "#B9DDEF";

const RACE_HOT_COLOR = "#cc0202";
const RACE_HOT_COLOR_LIGHT = "#ffd7d7";

const RACE_COLD_COLOR = "#0062ff";
const RACE_COLD_COLOR_LIGHT = "#99dbff";
//
// function getModalStyle() {
//     const top = 50;
//     const left = 50;
//
//     return {
//         top: `${top}%`,
//         left: `${left}%`,
//         transform: `translate(-${top}%, -${left}%)`,
//     };
// }
//
// const useStyles = makeStyles((theme) => ({
//   paper: {
//     position: 'absolute',
//     width: "80vw",
//     maxHeight: "80vh",
//     backgroundColor: theme.palette.background.paper
//   },
// }));

export const RaceInfoModal = props => {

    //const classes = useStyles();
    const [refreshing, setRefreshing] = useState(false);
    const [date, setDate] = useState("");
    const [venue, setVenue] = useState("");
    const [venueColor, setVenueColor] = useState("");
    const [modelName, setModelName] = useState("");
    const [modelColor, setModelColor] = useState("");
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.open && !isEmpty(props.raceInfo)) {
            _doGetData(props.raceInfo);
            setDate(props.raceInfo.s_date);
            setVenue(props.raceInfo.s_venue);
            setVenueColor(props.raceInfo.s_venue_color);
            setModelName(props.raceInfo.s_model_name);
            setModelColor(props.raceInfo.s_model_color);
        }
    }, [props]);

    const _doGetData = (o_race_info) => {

        setRefreshing(true);
        setData([]);

        const convertData = (data) => {
            const a_data = [];
            data.forEach((item, i) => {
                const a_prediction = [];
                if (item.hasOwnProperty("r_prediction")) {
                    item.r_prediction.forEach((prediction) => {
                        a_prediction.push({
                            s_horse_no: prediction.r_horse_no,
                            s_name: prediction.r_name,
                            i_result: prediction.r_result
                        })
                    })
                }
                let o_dividend = {};
                if (item.hasOwnProperty("r_dividend")) {
                    if (!isEmpty(item.r_dividend)) {
                        o_dividend = {
                            s_pool_code: item.r_dividend.r_pool_code,
                            s_pool_name: item.r_dividend.r_pool_name,
                            s_dividend: item.r_dividend.r_dividend
                        }
                    }
                }
                a_data.push({
                    s_race_no: item.r_race_no,
                    s_hot_cold: item.r_hot_cold,
                    //s_prediction_date: item.r_prediction_date,
                    o_dividend: o_dividend,
                    a_prediction: a_prediction
                })
            })

            setData(a_data);
            setRefreshing(false);
        }

        if (o_race_info.hasOwnProperty("s_meeting_id") && o_race_info.hasOwnProperty("s_model_id")) {
            HTTPRequest.HTTPGetRacePredictionInfo(o_race_info.s_meeting_id, o_race_info.s_model_id, function (result, data) {

                if (result === "success") {
                    convertData(data);
                } else {
                    if (data.r_stage == "0") {
                        return;
                    }
                }
            });
        }
    }

    const onHandleClose = () => {
        if (typeof props.onHandleClose !== "undefined") {
            setData([]);
            props.onHandleClose();
        }
    }

    const renderItem = (data, i) => {

        const getIconSource = (pool) => {
            if (pool === "WIN") {
                return WinIcon;
            } else if (pool === "QIN") {
                return QinIcon;
            } else if (pool === "TCE") {
                return TceIcon;
            } else if (pool === "QTT") {
                return QttIcon;
            } else {
                return null;
            }
        }

        let s_race_bg_color = RACE_DEFAULT_COLOR_LIGHT;
        let s_race_circle_color = RACE_DEFAULT_COLOR;
        if (data.s_hot_cold === "H") {
            s_race_bg_color = RACE_HOT_COLOR_LIGHT;
            s_race_circle_color = RACE_HOT_COLOR;
        } else if (data.s_hot_cold === "C") {
            s_race_bg_color = RACE_COLD_COLOR_LIGHT;
            s_race_circle_color = RACE_COLD_COLOR;
        }

        const s_dividend = !isEmpty(data.o_dividend) ? data.o_dividend.s_dividend : "";
        const s_pool_code = !isEmpty(data.o_dividend) ? data.o_dividend.s_pool_code : "";

        return (
            <Grid key={`item-${i}`} item xs={6} sm={4} md={3}>
                <div className="d-flex flex-column justify-content-center col-12 py-1" style={{ textAlign: "center", height: "70px" }}>
                    <div className="px-0" style={{ fontSize: "20px", color: "#686768" }}>{`第${data.s_race_no}場`}</div>
                    {s_dividend !== "" ?
                        <div className="d-flex justify-content-center align-items-center px-0" style={{ fontSize: "16px" }}>
                            <div className="pr-1">
                                <img
                                    style={{ width: 25, height: 25 }}
                                    src={getIconSource(s_pool_code)}
                                />
                            </div>
                            <NumericFormat value={s_dividend} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </div>
                        :
                        null
                    }
                </div>
                <div className="col-12 px-0">
                    {data.a_prediction.map((item, i) => {
                        const isOdd = i & 1;
                        return (
                            <div key={`${data.s_race_no}-${item.s_horse_no}`} className="col-12 px-0">
                                <div className="d-flex col-12" style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: isOdd ? HEADER_ODD_COLOR : HEADER_EVEN_COLOR }}>
                                    <div className="d-flex justify-content-center align-items-center" style={{
                                        backgroundColor: s_race_circle_color,
                                        borderColor: s_race_circle_color,
                                        borderWidth: 1,
                                        width: 28,
                                        height: 28,
                                        borderRadius: 28 / 2,
                                    }}>
                                        <div style={{ color: "#FFF" }}>
                                            {item.s_horse_no}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center" style={{
                                        width: 28,
                                        height: 28,
                                        marginTop: "-2px",
                                        marginLeft: "-1px"
                                    }}>
                                        <div style={{ fontSize: "22px", fontWeight: "bold", color: "#FFF", textShadow: "1px 1px #F00" }}>
                                            {item.i_result > 0 ? item.i_result : ""}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div>
                                            {item.s_name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Grid>
        )
    }

    return (
        <Dialog
            maxWidth="md"
            scroll={"paper"}
            open={props.open}
            onClose={onHandleClose}
        >
            <DialogTitle>
                <div className="col-12 px-0 d-flex justify-content-center">
                    <div>{date}</div>
                    <div className="pl-2" style={{ color: venueColor }}>{venue}</div>
                    <div className="pl-2" style={{ color: modelColor }}>{`(${modelName})`}</div>
                </div>
                <div className="position-absolute" style={{ right: "20px", cursor: "pointer" }} onClick={onHandleClose}>
                    <CloseIcon style={{ fontSize: 30 }} />
                </div>
            </DialogTitle>
            <DialogContent dividers style={{ padding: 0 }}>
                {refreshing ?
                    <Grid container alignItems="center" justifyContent="center" style={{ height: "50vh", width: "500px" }}>
                        <CircularProgress size={50} />
                    </Grid>

                    :
                    <Grid container spacing={0}>
                        {data.map((item, i) => {
                            return (
                                renderItem(item, i)
                            )
                        })}
                    </Grid>
                }

            </DialogContent>
        </Dialog>
    );
}

export default RaceInfoModal;
