Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var color = {
  mainColor: '#02A1D1',
  gray100: '#EFEFEF',
  gray200: '#D1D1D1',
  gray300: '#C3C3C3',
  gray400: '#BCBCBC',
  gray500: '#AFAFAF',
  gray600: '#898989',
  gray700: '#808080',
  gray800: '#767676',
  gray900: '#666666',
  background: '#F9F9F9',
  green100: "#E7F2ED",
  green200: "#E6EDEB",
  green300: "#DCE5E3",
  green400: "#C5EAE7",
  green500: "#B7E5DF",
  green600: "#38BAA7",
  green700: "#1CC6A9",
};
var _default = color;
exports.default = _default;
