export default class ServerParameters {

    static HTTP_PROTOCOL = "http://";
    static SERVER_ADDRESS = "218.253.95.254";
    static SERVER_PORT = ":50013";
    //static WEB_SERVER_PORT = ":80";

    static PRO_HTTP_PROTOCOL = `${window.location.protocol}//`;
    static PRO_SERVER_ADDRESS = "ai8race.com";
    static PRO_SERVER_PORT = "";
    // static PRO_SERVER_ADDRESS = process.env.NODE_ENV === "development" ? "218.253.95.254" : window.location.hostname;
    // static PRO_SERVER_PORT = process.env.NODE_ENV === "development" ? ":50013" : "";

    static SELECTION_DEVELOPMENT = 0;
    static SELECTION_PRODUCTION = 1;

    static SERVER_SELECTION = ServerParameters.SELECTION_PRODUCTION;

    static switchServer(option) {
        ServerParameters.SERVER_SELECTION = option;
    }

    static getURL() {
        if (ServerParameters.SERVER_SELECTION == ServerParameters.SELECTION_DEVELOPMENT) {
            return ServerParameters.HTTP_PROTOCOL + ServerParameters.SERVER_ADDRESS + ServerParameters.SERVER_PORT;
        } else {
            return ServerParameters.PRO_HTTP_PROTOCOL + ServerParameters.PRO_SERVER_ADDRESS + ServerParameters.PRO_SERVER_PORT;
        }
    }
}
