import {
    SHOW_LOADING,
    HIDE_LOADING,
} from "../../utils/constants/settings";

const INIT_STATE = {
    showLoading: false
};

//let LOADING_COUNT = 0;

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {

        case SHOW_LOADING: {
            //LOADING_COUNT++;

            return {
                ...state,
                showLoading: true
            }
        }

        case HIDE_LOADING: {
            // LOADING_COUNT--;
            // let showLoading = true;

            // if (LOADING_COUNT === 0 || action.force) showLoading = false;
            return {
                ...state,
                showLoading: false
            }
        }

        default: {
            return state;
        }
    }
};

export default reducerFunc;
