import color from './color';

export default {
    typography: {
        useNextVariants: true,
    },
    components: {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: "red"
                }
            }
        },
    },

    overrides: {
        MuiCircularProgress: {
            colorPrimary: {
                color: color["mainColor"]
            }
        },
        MuiTab: {
            root: {
                backgroundColor: "white",
            },
            textColorPrimary: {
                "&$selected": {
                    color: color["mainColor"]
                }
            },

        },
        MuiTabs: {
            indicator: {
                backgroundColor: color["mainColor"]
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: "white"
            }
        },
        MuiRadio: {
            root: {
                color: color["gray400"],
                '&$checked': {
                    color: color["mainColor"],
                },
            },
            colorSecondary: {
                color: color["gray400"],
                '&$checked': {
                    color: color["mainColor"],
                },
            }
        },
        MuiFormControlLabel: {
            label: {
                color: color["gray400"],
                fontWeight: 300
            }
        },
        MuiCheckbox: {
            root: {
                color: color["gray200"],
            },
            colorSecondary: {
                '&$checked': {
                    color: color["mainColor"],
                },
            },
        },
        MuiFormLabel: {
            root: {
                "&$focused": {
                    color: ""
                }
            },
            "&$focused": {
                "&$focused": {
                    "color": ""
                }
            }
        },
        MuiInput: {
            root: {
                backgroundColor: '#FFF'
            },
            underline: {
                "&:before": {
                    borderBottom: '2px solid ' + color['gray300']
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '2px solid ' + color["mainColor"]
                },
                "&:after": {
                    borderBottom: '2px solid ' + color["mainColor"]
                }
            }
        },
        MuiInputBase: {
            input: {
                textIndent: '6px'
            }
        },
        MuiTypography: {
            subheading: {
                fontFamily: 'unset'
            }
        },
        MuiListItem: {
            default: {
                paddingTop: 15,
                paddingBottom: 15
            },

        },
        MuiListItemText: {
            primary: {
                color: color["gray900"],
            }
        }
    }
};
