import ServerParams from './ServerParams';
import axios from 'axios';

export default class HTTPRequest {

    static HTTPGetRaceHistorySeason(callback) {

        const formData = new FormData();

        formData.append('f_oc', "race-history-season");

        HTTPRequest.doRequest(formData, function (res) {

            if (res.r_result === "success") {
                callback(res.r_result, res.r_data);
            } else {
                callback(res.r_result, {
                    r_error: res.r_error,
                    r_stage: res.r_stage
                });
            }
        });
    }

    static HTTPGetRaceHistoryDate(f_season, callback) {

        const formData = new FormData();

        formData.append('f_oc', "race-history-date");
        formData.append('f_version', "3");
        formData.append('f_season', f_season);

        HTTPRequest.doRequest(formData, function (res) {

            if (res.r_result === "success") {
                callback(res.r_result, res.r_data);
            } else {
                callback(res.r_result, {
                    r_error: res.r_error,
                    r_stage: res.r_stage
                });
            }
        });
    }

    static HTTPGetRacePredictionInfo(f_meeting_id, f_model_id, callback) {

        const formData = new FormData();

        formData.append('f_oc', "race-prediction-info");
        formData.append('f_meeting_id', f_meeting_id);
        formData.append('f_model_id', f_model_id);

        HTTPRequest.doRequest(formData, function (res) {

            if (res.r_result === "success") {
                callback(res.r_result, res.r_data);
            } else {
                callback(res.r_result, {
                    r_error: res.r_error,
                    r_stage: res.r_stage
                });
            }
        });
    }

    static doRequest(params, callback) {

        const url = ServerParams.getURL()+"/api.php";
        console.log(params);

        axios({
            method: 'post',
            url: url,
            data: params,
            timeout: 10000
        })
            .then(function (response) {
                console.log(response.data);
                callback(response.data);
            })
            .catch(function (error) {
                console.log(error.message);
                callback({r_result: "failed", r_error: "Request timeout", r_stage: "0"});
            })
    }
}
