import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useOutlet } from "react-router-dom";

//import Error from "./pages/error";
import Home from "./pages/home";
import Race from "./pages/race";

const AppRoutes = () => {

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/race" element={<Race />} />
        </Routes>
    )
}

export default AppRoutes;