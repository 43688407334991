import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import AppRoutes from "./AppRoutes";
import AppLayout from "./AppLayout";
import Wrapper from "./Wrapper";
import configureStore, { history } from './redux/store';

import { CircularProgress } from "@mui/material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

import Swal from 'sweetalert2'

import { onHideLoading } from './redux/actions/settings';

import './styles/app.scss';
import defaultTheme from './themes/defaultTheme';

const Div = styled('div')({});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const store = configureStore();

setupCache(axios, {
    ttl: 5 // seconds
});

axios.interceptors.request.use(config => {
    const { getState } = store;
    const { settings } = getState();

    if (typeof config.headers.Authorization === 'undefined') {
        if (settings && typeof settings.token === 'string') {
            // add bearer token to header
            config.headers.Authorization = 'Bearer ' + settings.token;
        }
    }

    // return config
    return config;
});

// Show alert if not success
axios.interceptors.response.use(response => {
    const { dispatch } = store;

    if (response.status === 200) {
        const { data } = response;
        console.log(`Cached: ${response.cached}`, data);
        const { r_result, r_error } = data;

        if (r_result !== "success" && typeof r_error !== "undefined") {
            dispatch(onHideLoading(true));

            if (r_error.match('Permission denied') ||
                r_error.match("signature verification failed") ||
                r_error.match("claim timestamp check failed") ||
                r_error.match("Compact JWS must be a string or Uint8Array")) {
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '錯誤',
                    text: r_error,
                    confirmButtonColor: '#009999'
                })
            }
            
            return Promise.reject(r_error);
        }
    } else {

        dispatch(onHideLoading(true));
        Swal.fire({
            icon: 'error',
            title: '錯誤',
            text: `Error ${response.status}: Please try again later.`,
            confirmButtonColor: '#009999'
        })
    }
    // Edit response config
    return response;
}, error => {
    const { dispatch } = store;
    console.log(error);

    dispatch(onHideLoading(true));
    Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Error [${error.code}] - Please try again later.`,
        confirmButtonColor: '#009999'
    })
    return Promise.reject(error);
});

const theme = createTheme({
    components: {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: 64,
                    borderBottom: "4px solid #02A1D1"
                }
            }
        },
    },
});

function App() {

    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter history={history}>
                    <Wrapper>
                        <ThemeProvider theme={theme}>
                            <AppLayout>
                                <Suspense
                                    fallback={
                                        <Div
                                            sx={{
                                                display: 'flex',
                                                minWidth: 0,
                                                alignItems: 'center',
                                                alignContent: 'center',
                                                height: '100%',
                                            }}
                                        >
                                            <CircularProgress sx={{ m: '-40px auto 0' }} />
                                        </Div>
                                    }
                                >
                                    <AppRoutes />
                                </Suspense>
                            </AppLayout>
                        </ThemeProvider>
                    </Wrapper>
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
